<template>
  <div class="max-w-screen-lg m-auto">
    <HeaderLogo />
    <div class="xs:mx-4">
      <h2 class="text-lg font-semibold">About This Site</h2>
      <p class="my-2 xs:text-sm sm:text-base">
        Welcome to IWC Web System Status Monitoring. Here, you can find
        real-time information about the performance and operational status of
        our system. Stay informed with the latest updates to ensure a smooth and
        reliable experience.
      </p>
      <div class="xs:py-1 sm:py-6">
        <ul>
          <li
            v-for="(item, index) in itemSystem"
            :key="index"
            class="my-4 py-3 px-4 rounded-xl bg-orange-50 shadow-sm"
          >
            <div class="flex justify-between xs:text-sm sm:text-base">
              <span class="font-semibold text-gray-700"> {{ item.name }}</span>
              <span
                class="font-bold"
                :class="item.status === 'A' ? 'text-green-600' : 'text-red-400'"
              >
                {{ item.status === "A" ? "OPERATIONAL" : "DOWNTIME" }}</span
              >
            </div>
          </li>
        </ul>
      </div>
      <div class="xs:text-sm sm:text-base">
        {{ dateTime }}
      </div>
    </div>
  </div>
</template>
<script>
import HeaderLogo from "@/components/common/HeaderLogo.vue";
import { onMounted, reactive, ref } from "vue";
import moment from "moment";
export default {
  components: {
    HeaderLogo,
  },
  setup() {
    const itemSystem = reactive([
      {
        name: "Digital Application Portal (DAP)",
        status: "A",
      },
      {
        name: "Automated Members Availment Portal (AMAP)",
        status: "A",
      },
      {
        name: "IWC Virtual Card",
        status: "A",
      },
      {
        name: "Company Tagging System",
        status: "A",
      },
      {
        name: "Deped Online Application System",
        status: "A",
      },
    ]);
    const dateTime = ref(null);
    onMounted(() => {
      dateTime.value = moment().format("MMMM Do YYYY, h:mm:ss a");
    });

    return {
      itemSystem,
      dateTime,
    };
  },
};
</script>
